import { API_URL, LIMIT } from '../../constants';
import { fetch } from '../fetchWrapper/fetchWrapper';
import { IGetProductsResponse } from './types';

export const getProducts = async (
  productId: string,
  name?: string,
  available?: boolean,
  scheduled?: boolean,
): Promise<IGetProductsResponse> => {
  const urlWithParams = new URL(`${API_URL}/availabilities?limit=${LIMIT}`);
  if (productId) urlWithParams.searchParams.append('after_id', productId);
  if (name) urlWithParams.searchParams.append('name', name);
  if (available !== undefined) urlWithParams.searchParams.append('available', available.toString());
  if (scheduled) urlWithParams.searchParams.append('scheduled', scheduled.toString());

  const res = await fetch(urlWithParams.href);

  if (!res.ok) {
    throw new Error('Error fetching products');
  }

  const json = await res.json();

  return json;
};
