import photo from '../../assets/images/image28.png';
import { useOktaAuth } from '@okta/okta-react';
import { useEffect } from 'react';
import { Button, Typography } from '@weareopenr/component-library';
import styles from './Welcome.module.scss';

const Welcome = () => {
  const { oktaAuth } = useOktaAuth();

  useEffect(() => {
    document.title = 'Welcome';
  }, []);

  const login = async () => {
    await oktaAuth.signInWithRedirect();
  };

  return (
    <div className={styles.container}>
      <div className={styles.left}>
        <div className={styles['login-container']}>
          <Typography size="XXL" weight="semi-bold" className={styles.text}>
            Welcome back
          </Typography>
          <Button primary size="full-width" onClick={login}>
            Login
          </Button>
        </div>
      </div>
      <div className={styles.right}>
        <img src={photo} className={styles.image} alt="People in a restaurant" />
      </div>
    </div>
  );
};

export default Welcome;
