import { useOktaAuth } from '@okta/okta-react';
import { Typography } from '@weareopenr/component-library';
import { CSSTransition } from 'react-transition-group';
import classNames from 'classnames/bind';
import Avatar from '../Avatar/Avatar';
import styles from './UserDropdown.module.scss';
import { useRef } from 'react';

const cx = classNames.bind(styles);

interface IUserDropDown {
  open: boolean;
  initials: string;
  name: string;
}

const UserDropdown = ({ open, initials, name }: IUserDropDown): JSX.Element => {
  const { oktaAuth } = useOktaAuth();
  const nodeRef = useRef(null);

  const logout = async () => {
    await oktaAuth.signOut();
  };

  return (
    <CSSTransition nodeRef={nodeRef} in={open} timeout={300} unmountOnExit>
      <div
        className={cx({ 'user-dropdown-closed': !open }, 'user-dropdown-container')}
        data-testid="userDropdownContainer"
        ref={nodeRef}
      >
        <ul className={styles['user-dropdown']} role="menu">
          <li aria-disabled className={styles['user-details']} role="menuitem">
            <Avatar initials={initials} />
            <div className={styles['text-wrapper']}>
              <Typography size="M" weight="semi-bold">
                {name}
              </Typography>
              <Typography size="S" weight="regular">
                Admin
              </Typography>
            </div>
          </li>
          <li className={styles['logout-button-container']} role="menuitem">
            <a
              className={styles['logout-button']}
              href="#"
              role="button"
              data-cy="logout-button"
              onClick={logout}
            >
              <Typography size="M" weight="regular" className={styles['logout-text']}>
                Logout
              </Typography>
            </a>
          </li>
        </ul>
      </div>
    </CSSTransition>
  );
};

export default UserDropdown;
