import classNames from 'classnames/bind';
import { Typography } from '@weareopenr/component-library';
import styles from './Filter.module.scss';
import { ReactElement } from 'react';

interface IFilterProps {
  filterName: string;
  active: string;
  handleClick: (name: string, filter?: string) => void;
}
const Filter = ({ filterName, active, handleClick }: IFilterProps): ReactElement => {
  const cx = classNames.bind(styles);

  return (
    <button
      className={cx('button-tab', { selected: active === filterName })}
      onClick={() => handleClick('all')}
    >
      <Typography
        className={cx('tab-text', { 'selected-text': active === filterName })}
        weight="semi-bold"
      >
        {filterName}
      </Typography>
    </button>
  );
};

export default Filter;
