import React, { MouseEvent } from 'react';
import { Typography } from '@weareopenr/component-library';
import classNames from 'classnames/bind';
import styles from './Avatar.module.scss';

interface IAvatarProps {
  initials: string;
  onClick?: (event: MouseEvent<HTMLElement>) => void;
  hover?: boolean;
}

const Avatar = ({ initials, onClick, hover }: IAvatarProps): React.ReactElement => {
  const cx = classNames.bind(styles);

  return (
    <button
      className={cx('avatar', { 'avatar-hover': hover })}
      data-cy="avatar"
      onClick={onClick}
      type="button"
    >
      <Typography weight="semi-bold" className={styles.initials}>
        {initials}
      </Typography>
    </button>
  );
};

export default Avatar;
