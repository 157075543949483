import { Prompt, Typography } from '@weareopenr/component-library';
import { getDateString, getTimeString } from 'src/utils';
import styles from './CancelSchedulePrompt.module.scss';
import { IGetProductData } from 'src/api/getProducts/types';
import useCancelScheduledAvailability from 'src/hooks/useCancelScheduledAvailability/useCancelScheduledAvailabilty';
import BrandNames from '../BrandNames';

interface ICancelSchedulePromptProps {
  productToCancel: IGetProductData;
  openCancelPrompt: boolean;
  closePrompt: () => void;
}

const CancelSchedulePrompt = ({
  productToCancel,
  openCancelPrompt,
  closePrompt,
}: ICancelSchedulePromptProps) => {
  const { mutate, isLoading } = useCancelScheduledAvailability();

  const handleCancel = (): void => {
    mutate(
      {
        productId: productToCancel.product_id,
      },
      {
        onSuccess: () => {
          closePrompt();
        },
      },
    );
  };

  return (
    <Prompt
      open={openCancelPrompt}
      closePrompt={closePrompt}
      buttonHandler={handleCancel}
      buttonText="Cancel schedule"
      promptHeading="Scheduled availability"
      size="large"
      isSubmitting={isLoading}
      backButton
    >
      <Typography size="L">
        <strong className={styles['semi-bold']}>{productToCancel.name}</strong> in{' '}
        {<BrandNames brands={productToCancel.brands} />} will be
        {productToCancel.disabled_from ? ' unavailable ' : ' available '}
        at{' '}
        {productToCancel.disabled_from && (
          <>
            <strong className={styles['semi-bold']}>
              {getTimeString(productToCancel.disabled_from)}
            </strong>
            ,{' '}
            <strong className={styles['semi-bold']}>
              {getDateString(productToCancel.disabled_from)}
            </strong>
          </>
        )}
        {productToCancel.disabled_until && (
          <>
            <strong className={styles['semi-bold']}>
              {getTimeString(productToCancel.disabled_until)}
            </strong>
            ,{' '}
            <strong className={styles['semi-bold']}>
              {getDateString(productToCancel.disabled_until)}
            </strong>
          </>
        )}
      </Typography>
    </Prompt>
  );
};

export default CancelSchedulePrompt;
