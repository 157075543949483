import { useInfiniteQuery } from '@tanstack/react-query';
import { getProducts } from '../../api/getProducts/getProducts';
import { IGetProductsResponse } from 'src/api/getProducts/types';

const useGetProductsInfiniteScroll = (
  name?: string,
  available?: boolean,
  scheduledFilter?: boolean,
) => {
  const { status, data, fetchNextPage, hasNextPage } = useInfiniteQuery<IGetProductsResponse>({
    queryKey: ['products', name, available, scheduledFilter],
    queryFn: ({ pageParam = '' }) => getProducts(pageParam, name, available, scheduledFilter),
    getNextPageParam: (lastPage) => lastPage.data[lastPage.data.length - 1]?.product_id,
    refetchOnWindowFocus: false,
  });

  return { status, data, fetchNextPage, hasNextPage };
};

export default useGetProductsInfiniteScroll;
