import React from 'react';
import { IGetProductBrand } from 'src/api/getProducts/types';
import styles from './BrandNames.module.scss';

interface IBrandNameProps {
  brands: IGetProductBrand[];
}

const BrandNames = ({ brands }: IBrandNameProps): JSX.Element => {
  return (
    <>
      {brands.map((brand, index) => {
        return (
          <React.Fragment key={brand.brand_id}>
            <strong key={brand.brand_id} className={styles['semi-bold']}>
              {brand.name}
            </strong>
            {index < brands.length - 2 ? ', ' : index < brands.length - 1 ? ' and ' : ''}
          </React.Fragment>
        );
      })}
    </>
  );
};

export default BrandNames;
