import React from 'react';
import classNames from 'classnames/bind';
import { Typography } from '@weareopenr/component-library';
import styles from './IconButton.module.scss';

const cx = classNames.bind(styles);

export interface IIconButtonProps {
  active: boolean;
  alt: string;
  src: string;
  text: string;
}

const IconButton = ({ active, alt, text, src }: IIconButtonProps): React.ReactElement => (
  <button className={cx('icon-button', { 'active-button': active })} type="button">
    <img src={src} alt={alt} className={cx({ 'active-filter': active })}/>
    <Typography className={cx('icon-text', { 'active-text': active })} weight="semi-bold">
      {text}
    </Typography>
  </button>
);

export default IconButton;
