import { Route, Switch, useHistory } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { LoginCallback, SecureRoute, Security } from '@okta/okta-react';
import { Products, Welcome } from './pages';
import '@weareopenr/component-library/build/global.scss';
import './styles/global.scss';
import { NotificationContext } from '@weareopenr/component-library';

declare global {
  interface Window {
    Cypress: Cypress.Cypress;
  }
}

const CustomLoginCallback = () => {
  const history = useHistory();
  return (
    <LoginCallback
      errorComponent={() => {
        history.push('/login');
        return null;
      }}
    />
  );
};

const Routes = () => {
  return (
    <Switch>
      <Route exact path="/login" component={Welcome} />
      <Route path="/login/callback" component={CustomLoginCallback} />
      <SecureRoute path="/" component={Products} />
    </Switch>
  );
};

const App = (): React.ReactElement => {
  const history = useHistory();
  const oktaAuth = new OktaAuth({
    issuer: process.env.REACT_APP_OKTA_ISSUER,
    clientId: process.env.REACT_APP_OKTA_CLIENTID,
    redirectUri: `${window.location.origin}/login/callback`,
    postLogoutRedirectUri: window.location.origin,
    scopes: ['openid', 'profile', 'email'],
    pkce: true,
  });

  const restoreOriginalUri = (_oktaAuth: OktaAuth, originalUri: string) => {
    history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
  };
  const onAuthRequired = () => {
    const previousAuthState = oktaAuth.authStateManager.getPreviousAuthState();
    if (!previousAuthState || !previousAuthState.isAuthenticated) {
      history.replace('/login');
    }
  };

  return window.Cypress ? (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <Routes />
    </Security>
  ) : (
    <Security
      oktaAuth={oktaAuth}
      restoreOriginalUri={restoreOriginalUri}
      onAuthRequired={onAuthRequired}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <NotificationContext>
          <Routes />
        </NotificationContext>
      </LocalizationProvider>
    </Security>
  );
};

export default App;
