const getInitials = (name: string): string => {
  if (name.length === 0) return '';

  const nameArr = name.split(' ');

  return nameArr
    .map((name, index) => {
      if (index === 0 || index === nameArr.length - 1) {
        return name[0].toLocaleUpperCase();
      }
    })
    .join('');
};

export default getInitials;
