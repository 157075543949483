import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import IconButton from '../IconButton/IconButton';
import Avatar from '../Avatar';
import menuItemIcon from '../../assets/images/menu-item-icon.svg';
import openrLogo from '../../assets/images/openr-logo.svg';
import styles from './Sidebar.module.scss';
import UserDropdown from '../UserDropdown';
import { getInitials } from 'src/utils';
import { useClickOutside } from 'src/hooks';

const Sidebar = (): React.ReactElement => {
  const [showUserDropdown, setShowUserDropdown] = useState<boolean>(false);
  const { authState } = useOktaAuth();
  const name = authState?.idToken?.claims?.name ?? '';
  const initials = getInitials(name);

  const handleOpenUserMenu = () => {
    setShowUserDropdown((prevState) => !prevState);
  };

  const domNode = useClickOutside(() => {
    setShowUserDropdown(false);
  });

  return (
    <aside className={styles.sidebar}>
      <div className={styles['logo-icons-wrapper']}>
        <Link to="/">
          <img src={openrLogo} alt="Openr Logo" />
        </Link>
        <IconButton active alt="Products" src={menuItemIcon} text="Products" />
      </div>
      <div className={styles['avatar-wrapper']} data-cy="user-container" ref={domNode.ref}>
        <Avatar initials={initials} onClick={handleOpenUserMenu} hover />
        <UserDropdown open={showUserDropdown} initials={initials} name={name} />
      </div>
    </aside>
  );
};

export default Sidebar;
