import { API_URL } from '../../constants';
import { fetch } from '../fetchWrapper/fetchWrapper';
import { IGetProductsResponse } from '../getProducts/types';
import { IUpdateProductAvailability } from './types';

export const updateProductAvailability = async ({
  productId,
  requestBody,
}: IUpdateProductAvailability): Promise<IGetProductsResponse> => {
  const url = `${API_URL}/availabilities/products/${productId}`;

  const res = await fetch(url, {
    method: 'PATCH',
    body: JSON.stringify(requestBody),
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (!res.ok) {
    throw new Error('Error updating product availability');
  }

  const json = await res.json();

  return json;
};
