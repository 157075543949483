import { Dispatch } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Typography, useNotifications } from '@weareopenr/component-library';
import { updateProductAvailability } from 'src/api/updateProductAvailability/updateProductAvailability';
import styles from './useUpdateProductAvailability.module.scss';
import { IUpdateProductAvailability } from "../../api/updateProductAvailability/types";

const useUpdateProductAvailability = (productName: string, setLoading?:Dispatch<boolean> ) => {
  const queryClient = useQueryClient();
  const createNotification = useNotifications();

  const mutate = useMutation({
    mutationFn: (req: IUpdateProductAvailability) => {
      if(setLoading){
        setLoading(true)
      }
      return updateProductAvailability(req)},
    onSuccess: (_newAvailability, variables) => {

      if (variables.requestBody.disabled_from || variables.requestBody.disabled_until) {
        createNotification({
          severity: 'success',
          message: (
            <Typography size="M" weight="regular" color="secondary">
              You have successfully scheduled{' '}
              <strong className={styles['semi-bold']}>{productName}</strong>
            </Typography>
          ),
        });
      }
      queryClient.refetchQueries(['products']);
    },
    onError: (_error, variables) => {
      if(setLoading){
        setLoading(false)
      }
      if (variables.requestBody.disabled_from || variables.requestBody.disabled_until) {
        createNotification({
          severity: 'error',
          message: (
            <Typography size="M" weight="regular" color="secondary">
              Unable to schedule <strong className={styles['semi-bold']}>{productName}</strong>,
              please try again later
            </Typography>
          ),
        });
      } else {
        createNotification({
          severity: 'error',
          message: (
            <Typography size="M" weight="regular" color="secondary">
              Failed to update <strong className={styles['semi-bold']}>{productName}</strong> on all published menus,
              please try again later
            </Typography>
          ),
        });
      }
    },
  });
  return mutate;
};

export default useUpdateProductAvailability;
