import React, { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { CircularProgress } from '@mui/material';
import { Typography } from '@weareopenr/component-library';
import ProductsTable from '../../components/ProductsTable';
import Sidebar from '../../components/Sidebar';
import Header from '../../components/Header';
import Filter from 'src/components/Filter';
import styles from './Products.module.scss';
import { useGetProductsInfiniteScroll } from 'src/hooks';
import { ScheduleAvailabilityModal } from 'src/components/ScheduleAvailabilityModal/ScheduleAvailabilityModal';
import { IGetProductData } from 'src/api/getProducts/types';
import CancelSchedulePrompt from 'src/components/CancelSchedulePrompt/CancelSchedulePrompt';

const Products = (): React.ReactElement => {
  const [name, setName] = useState<string>('');
  const [openCancelPrompt, setOpenCancelPrompt] = useState<boolean>(false);
  const [available, setAvailable] = useState<boolean | undefined>(undefined);
  const [scheduledFilter, setScheduledFilter] = useState<boolean | undefined>(false);
  const [active, setActive] = useState<string>('All Products');
  const { status, data, fetchNextPage, hasNextPage } = useGetProductsInfiniteScroll(
    name,
    available,
    scheduledFilter,
  );
  const [openScheduleModal, setOpenScheduleModal] = useState<boolean>(false);
  const [productToSchedule, setProductToSchedule] = useState<IGetProductData>();
  const [productToCancel, setProductToCancel] = useState<IGetProductData | undefined>(undefined);
  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage, inView]);

  useEffect(() => {
    if (openScheduleModal) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [openScheduleModal]);

  const handleClick = (name: string, filter?: boolean) => {
    setActive(name);
    setAvailable(filter);
    if (name === 'Scheduled') {
      setScheduledFilter(filter);
      setAvailable(undefined);
    } else {
      setAvailable(filter);
      setScheduledFilter(false);
    }
  };

  return (
    <>
      {productToSchedule && (
        <ScheduleAvailabilityModal
          open={openScheduleModal}
          closeModal={() => setOpenScheduleModal(false)}
          product={productToSchedule}
        />
      )}
      {productToCancel && (
        <CancelSchedulePrompt
          productToCancel={productToCancel}
          openCancelPrompt={openCancelPrompt}
          closePrompt={() => setOpenCancelPrompt(false)}
        />
      )}
      <Sidebar />
      <main className={styles['products-layout']}>
        <Header title="Products" setSearchQuery={setName} status={status} />
        <div className={styles['tab-container']}>
          <Filter
            filterName="All Products"
            active={active}
            handleClick={() => handleClick('All Products')}
          />
          <Filter
            filterName="Available"
            active={active}
            handleClick={() => handleClick('Available', true)}
          />
          <Filter
            filterName="Unavailable"
            active={active}
            handleClick={() => handleClick('Unavailable', false)}
          />
          <Filter
            filterName="Scheduled"
            active={active}
            handleClick={() => handleClick('Scheduled', true)}
          />
        </div>
        {status === 'loading' ? (
          <div className={styles['spinner-container']}>
            <CircularProgress />
          </div>
        ) : (
          <>
            {name && (
              <div className={styles['search-result']}>
                <Typography size="XL" inline>
                  {data && data.pages[0].data.length > 0
                    ? 'Search results for'
                    : 'No results found for'}{' '}
                </Typography>
                <Typography size="XL" weight="semi-bold" inline>
                  &quot;{name}&quot;
                </Typography>
              </div>
            )}
            {data && data.pages[0].data.length > 0 && (
              <ProductsTable
                setOpenCancelPrompt={setOpenCancelPrompt}
                products={data}
                innerRef={ref}
                setOpenScheduleModal={setOpenScheduleModal}
                setProductToSchedule={setProductToSchedule}
                setProductToCancel={setProductToCancel}
                searchRequest={name}
                availabilityFilterSelected={available}
                scheduledFilter={scheduledFilter}
              />
            )}
          </>
        )}
      </main>
    </>
  );
};

export default Products;
