import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Typography, useNotifications } from '@weareopenr/component-library';
import { cancelScheduledAvailability } from 'src/api/cancelScheduledAvailability/cancelScheduledAvailability';
import styles from './useCancelScheduledAvailability.module.scss';

const useCancelScheduledAvailability = () => {
  const queryClient = useQueryClient();
  const createNotification = useNotifications();

  const mutate = useMutation(cancelScheduledAvailability, {
    onSuccess: () => {
      createNotification({
        severity: 'success',
        message: (
          <Typography size="M" weight="regular" color="secondary">
            You have successfully <strong className={styles['semi-bold']}>cancelled</strong> the
            schedule
          </Typography>
        ),
      });
      queryClient.refetchQueries(['products']);
    },
    onError: () => {
      createNotification({
        severity: 'error',
        message: 'Unable to cancel schedule, please try again later',
      });
    },
  });
  return mutate;
};

export default useCancelScheduledAvailability;
