export const fetch = (url: string, options?: RequestInit) => {
  const oktaTokenStorage = localStorage.getItem('okta-token-storage');

  if (typeof oktaTokenStorage !== 'string') {
    throw new Error('There is no access token in local storage');
  }

  const parsedTokenStorage = JSON.parse(oktaTokenStorage);
  const accessToken = parsedTokenStorage.accessToken.accessToken;

  const requestOptions: RequestInit = {
    ...options,
    headers: {
      ...options?.headers,
      Authorization: `Bearer ${accessToken}`,
    },
  };

  return window.fetch(url, requestOptions);
};
