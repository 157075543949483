import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Button, RadioButton, Typography, Modal } from '@weareopenr/component-library';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers';
import { IGetProductData } from 'src/api/getProducts/types';
import { checkDateInFuture } from 'src/utils';
import photo from '../../assets/images/schedule-modal-image.png';
import styles from './ScheduleAvailabilityModal.module.scss';
import { IProductAvailabilityRequestBody } from 'src/api/updateProductAvailability/types';
import { useUpdateProductAvailability } from 'src/hooks';
import scheduleIcon from '../../assets/images/schedule-icon.svg';
import BrandNames from '../BrandNames';

interface IScheduleAvailabilityModalContentProps {
  product: IGetProductData;
  closeModal: () => void;
}
interface IScheduleAvailabilityModalProps extends IScheduleAvailabilityModalContentProps {
  open: boolean;
}

dayjs.extend(timezone);
dayjs.tz.setDefault(dayjs.tz.guess());

const SelectedDay = (pickersDayProps: PickersDayProps<string>) => {
  return <PickersDay {...pickersDayProps} classes={{ selected: styles['selected-day'] }} />;
};

const DateTimeIcon = () => {
  return <img className={styles['schedule-icon']} src={scheduleIcon} alt="calendar icon" />;
};

const ScheduleAvailabilityModalContent = ({
  product,
  closeModal,
}: IScheduleAvailabilityModalContentProps) => {
  const unavailableFrom = 'disabled_from';
  const availableFrom = 'disabled_until';
  const { mutate, isLoading } = useUpdateProductAvailability(product.name);
  const [radioValue, setRadioValue] = useState<string>(availableFrom);
  const { control, setValue, handleSubmit, reset } = useForm<IProductAvailabilityRequestBody>({
    defaultValues: {
      disabled_from: '',
      disabled_until: '',
    },
    mode: 'onChange',
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRadioValue(event.target.value);
    reset();
  };

  const handleDateTime = (newValue: string | null) => {
    const selectedDateTime = dayjs(newValue).toISOString();
    setValue(radioValue === availableFrom ? availableFrom : unavailableFrom, selectedDateTime);
  };

  const handleScheduleSubmit = (data: IProductAvailabilityRequestBody): void => {
    data.disabled_from === '' ? delete data.disabled_from : delete data.disabled_until;

    mutate(
      {
        productId: product.product_id,
        requestBody: data,
      },
      {
        onSuccess: () => {
          closeModal();
        },
      },
    );
  };

  return (
    <form name="schedule-availability" onSubmit={handleSubmit(handleScheduleSubmit)}>
      <main>
        <Typography weight="semi-bold" size="XXL" className={styles['modal-title']}>
          Schedule Availability
        </Typography>
        <Typography>
          For <strong className={styles['semi-bold']}>{product.name} </strong>
          in {<BrandNames brands={product.brands} />}
        </Typography>
        <div className={styles['radio-container']}>
          <RadioButton
            checked={radioValue === availableFrom}
            name="schedule-time"
            onChange={handleChange}
            value={availableFrom}
          >
            Available from
          </RadioButton>
          <RadioButton
            checked={radioValue === unavailableFrom}
            name="schedule-time"
            onChange={handleChange}
            value={unavailableFrom}
          >
            Unavailable from
          </RadioButton>
        </div>
        <Controller
          control={control}
          rules={{
            validate: {
              checkTimeInPast: (v) =>
                checkDateInFuture(dayjs(v).toDate()) || 'Date and time cannot be in the past',
              validDate: (v) => dayjs(v).isValid() || 'Please select valid date and time',
            },
            required: 'Please select a date and time',
          }}
          name={radioValue === availableFrom ? availableFrom : unavailableFrom}
          render={({ field: { value }, fieldState: { error } }) => (
            <DateTimePicker
              label="Date and time"
              value={value ?? ''}
              onChange={handleDateTime}
              slotProps={{
                textField: {
                  sx: {
                    '& .MuiOutlinedInput-root, .MuiInputLabel-root': {
                      fontFamily: 'Poppins, sans-serif',
                    },
                  },
                  error: !!error,
                  helperText: error?.message,
                  id: 'date-time-picker',
                  className: styles['text-field'],
                },
              }}
              slots={{
                day: SelectedDay,
                openPickerIcon: DateTimeIcon,
              }}
              ampm={false}
              format="DD/MM/YYYY HH:mm"
              disablePast
            />
          )}
        />
        <div className={styles['action-content']}>
          <Button onClick={closeModal} size="full-width">
            Back
          </Button>
          <Button type="submit" primary size="full-width" loading={isLoading}>
            Schedule
          </Button>
        </div>
      </main>
    </form>
  );
};

export const ScheduleAvailabilityModal = ({
  open,
  closeModal,
  product,
}: IScheduleAvailabilityModalProps) => {
  return (
    <Modal closeModal={closeModal} open={open} image={photo} imageAlt="chef preparing food">
      <ScheduleAvailabilityModalContent closeModal={closeModal} product={product} />
    </Modal>
  );
};
