const getDateTimeString = (dateTimeString: string): string => {
  const dateTime = new Date(dateTimeString);

  return `${dateTime.toLocaleDateString('en-GB', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  })} @ ${dateTime.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' })}`;
};

export default getDateTimeString;
