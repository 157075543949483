import { ReactElement, Dispatch, SetStateAction } from 'react';
import { InfiniteData } from '@tanstack/react-query';
import { IGetProductData, IGetProductsResponse } from '../../api/getProducts/types';
import { titleCaseRecipeType } from './titleCaseRecipeType/titleCaseRecipeType';
import { TABLE_HEADINGS } from '../../constants';
import styles from './ProductsTable.module.scss';
import ProductControl from '../ProductControl';
import { useFlags } from 'flagsmith/react';

export interface IProductsTableProps {
  products: InfiniteData<IGetProductsResponse>;
  innerRef?: (node?: Element | null | undefined) => void;
  setOpenScheduleModal: Dispatch<SetStateAction<boolean>>;
  setProductToSchedule: Dispatch<SetStateAction<IGetProductData | undefined>>;
  setProductToCancel: Dispatch<SetStateAction<IGetProductData | undefined>>;
  searchRequest: string;
  availabilityFilterSelected?: boolean;
  setOpenCancelPrompt: Dispatch<SetStateAction<boolean>>;
  scheduledFilter?: boolean;
}

const ProductsTable = ({
  products,
  innerRef,
  setOpenScheduleModal,
  setProductToSchedule,
  setProductToCancel,
  searchRequest,
  availabilityFilterSelected,
  setOpenCancelPrompt,
  scheduledFilter,
}: IProductsTableProps): ReactElement => {
  const flags = useFlags(['in_venue_brand_logo']);

  return (
    <div className={styles['page-container']}>
      <table className={styles['products-table']}>
        <thead>
          <tr>
            {TABLE_HEADINGS.map((tableHeading) => (
              <th key={tableHeading} scope="col" className={styles['product-heading']}>
                {tableHeading}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {products.pages?.length > 0 &&
            products.pages?.map((page) =>
              page?.data.map((product) =>
                product.brands.map((brand) => (
                  <tr
                    key={`${product.product_id}${brand.brand_id}`}
                    className={styles['product-row']}
                    ref={innerRef}
                  >
                    <td className={styles['product-cell']}>{product.name}</td>
                    <td className={styles['product-cell']}>{titleCaseRecipeType(product.type)}</td>
                    <td className={styles['product-cell']}>
                      <div className={styles['brand-wrapper']}>
                        {brand.logo_url && flags.in_venue_brand_logo.enabled && (
                          <img
                            src={brand.logo_url}
                            alt={brand.logo_url}
                            className={styles['brand-image']}
                          />
                        )}
                        {brand.name}
                      </div>
                    </td>
                    <td className={styles['product-cell']}>
                      <div className={styles['availability-container']}>
                        <ProductControl
                          product={product}
                          searchRequest={searchRequest}
                          availabilityFilterSelected={availabilityFilterSelected}
                          scheduledFilter={scheduledFilter}
                          setOpenScheduleModal={setOpenScheduleModal}
                          setProductToSchedule={setProductToSchedule}
                          setOpenCancelPrompt={setOpenCancelPrompt}
                          setProductToCancel={setProductToCancel}
                        />
                      </div>
                    </td>
                  </tr>
                )),
              ),
            )}
        </tbody>
      </table>
    </div>
  );
};

export default ProductsTable;
