import { Typography, SearchField } from '@weareopenr/component-library';
import styles from './Header.module.scss';
import { Dispatch, SetStateAction, KeyboardEvent, ChangeEvent } from 'react';

interface IHeaderProps {
  title: string;
  setSearchQuery: Dispatch<SetStateAction<string>>;
  status: string;
}

const Header = ({ title, setSearchQuery, status }: IHeaderProps) => {
  const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      setSearchQuery(event.currentTarget.value.toLowerCase());
      event.currentTarget.blur();
    }
  };

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.currentTarget.value === '' && !(event.nativeEvent instanceof InputEvent)) {
      setSearchQuery('');
    }
  };

  return (
    <header>
      <div className={styles['header-gradient']} />
      <div className={styles['header-wrapper']}>
        <Typography size="XXL" weight="semi-bold" className={styles.title}>
          {title}
        </Typography>
        <div className={styles['search-wrapper']}>
          <SearchField
            onKeyDown={handleKeyPress}
            onChange={handleOnChange}
            isDisabled={status === 'loading'}
            placeholder="Search"
          />
        </div>
      </div>
    </header>
  );
};

export default Header;
