import { API_URL } from '../../constants';
import { fetch } from '../fetchWrapper/fetchWrapper';
import { IGetProductsResponse } from '../getProducts/types';
import { ICancelScheduledAvailability } from './types';

export const cancelScheduledAvailability = async ({
  productId,
}: ICancelScheduledAvailability): Promise<IGetProductsResponse[]> => {
  const url = `${API_URL}/availabilities/products/${productId}/cancel`;

  const res = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (!res.ok) {
    throw new Error('Error cancelling scheduled availability');
  }

  const json = await res.json();

  return json;
};
