import { Dispatch, SetStateAction, useEffect } from 'react';
import classNames from 'classnames/bind';
import styles from './Toggle.module.scss';
import { Typography } from '@weareopenr/component-library';
import { CircularProgress } from '@mui/material';

const cx = classNames.bind(styles);

interface IToggleProps {
  toggleChecked: boolean;
  handleClick?: () => void;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;

}
const Toggle = ({ toggleChecked, handleClick, loading, setLoading}: IToggleProps) => {
  useEffect(() => {
    setLoading(false);
  }, [toggleChecked]);

  return (
    <span
      className={cx('toggle-container', { 'checked-toggle-container': toggleChecked })}
      onClick={handleClick}
      role="button"
    >
      <div className={cx('toggle', { 'checked-toggle': toggleChecked })}>
        <span className={cx('slider', { 'checked-slider': toggleChecked })} />
        {loading ? (
          <div
            className={cx('spinner-container', {
              'spinner-on': toggleChecked,
              'spinner-off': !toggleChecked,
            })}
          >
            <CircularProgress />
          </div>
        ) : (
          <Typography
            color="secondary"
            size="L"
            weight="semi-bold"
            className={cx('toggle-text', { 'checked-toggle-text': toggleChecked })}
          >
            {toggleChecked ? 'On' : 'Off'}
          </Typography>
        )}
      </div>
    </span>
  );
};

export default Toggle;
