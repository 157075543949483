import { Dispatch, ReactElement, SetStateAction, useState } from 'react';
import { Typography } from '@weareopenr/component-library';
import Toggle from '../Toggle';
import { IGetProductData } from '../../api/getProducts/types';
import { useUpdateProductAvailability } from 'src/hooks';
import styles from './ProductControl.module.scss';
import schedule from '../../assets/images/schedule-icon.svg';
import { getDateTimeString } from 'src/utils';
import removeIcon from '../../assets/images/remove-icon.svg';

interface IToggleProps {
  product: IGetProductData;
  searchRequest: string;
  availabilityFilterSelected: boolean | undefined;
  setOpenScheduleModal: Dispatch<SetStateAction<boolean>>;
  setProductToSchedule: Dispatch<SetStateAction<IGetProductData | undefined>>;
  setOpenCancelPrompt: Dispatch<SetStateAction<boolean>>;
  setProductToCancel: Dispatch<SetStateAction<IGetProductData | undefined>>;
  scheduledFilter?: boolean;
}

const ProductControl = ({
  product,
  setOpenScheduleModal,
  setProductToSchedule,
  setOpenCancelPrompt,
  setProductToCancel,
  }: IToggleProps): ReactElement => {
  const [loading, setLoading] = useState<boolean>(false);
  const {mutate} = useUpdateProductAvailability(product.name, setLoading);

  const handleAvailability = (enabled: boolean, productId: string): void => {
    mutate({
      productId,
      requestBody: {enabled: !enabled,}
    });
  };

  return (
    <>
      <Toggle
        toggleChecked={product.enabled}
        handleClick={() => handleAvailability(product.enabled, product.product_id)}
        loading={loading}
        setLoading={setLoading}
      />
      {product.disabled_from || product.disabled_until ? (
        <div className={styles['scheduled-product-container']}>
          <Typography size="S" className={styles['schedule-text']}>
            {product.disabled_until ? 'Available ' : 'Unavailable'} from{' '}
            {(product.disabled_until && getDateTimeString(product.disabled_until)) ||
              (product.disabled_from && getDateTimeString(product.disabled_from))}
          </Typography>
          <button
            aria-label="close icon"
            className={styles['close-icon']}
            onClick={() => {
              setOpenCancelPrompt(true);
              product && setProductToCancel(product);
            }}
          >
            <img alt="cancel" src={removeIcon} />
          </button>
        </div>
      ) : (
        <button
          className={styles['schedule-container']}
          onClick={() => {
            setOpenScheduleModal((prev) => !prev);
            setProductToSchedule(product);
          }}
        >
          <img src={schedule} alt={'schedule icon'} height={32} width={32} />
          <Typography size="L" className={styles['schedule-text']}>
            Schedule
          </Typography>
        </button>
      )}
    </>
  );
};

export default ProductControl;
