const getDateString = (dateTimeString: string): string => {
  const dateTime = new Date(dateTimeString);

  return dateTime.toLocaleDateString('en-GB', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });
};

export default getDateString;
