import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { FlagsmithProvider } from 'flagsmith/react';
import flagsmith from 'flagsmith';
import TagManager from 'react-gtm-module';

TagManager.initialize({
  gtmId: 'GTM-TKM7P7N',
});

const queryClient = new QueryClient();
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <FlagsmithProvider
          options={{
            environmentID: process.env.REACT_APP_FLAGSMITH_ENV as string,
          }}
          flagsmith={flagsmith}
        >
          <App />
        </FlagsmithProvider>
        <ReactQueryDevtools />
      </QueryClientProvider>
    </BrowserRouter>
  </React.StrictMode>,
);
